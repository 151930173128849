*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiMenu-paper {
  background: white;
  color: orangered
}

a:-webkit-any-link {
  text-decoration: none
}

:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #bebebe;
}

::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 10px;
}

.chq-atc--button {
  background-color: red;
}

@media(max-width: 768px) {
  :-webkit-scrollbar {
    display: none;
  }
}