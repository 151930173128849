.chq-atc {
  width: 100%;
}

.chq-atc .chq-atc--button>svg {
  display: none;
}

.chq-atc--button {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.chq-atc--button:hover {
  background-color: transparent;
}