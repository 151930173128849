.collageContainer{
    margin: 5px 0px 20px 0px;
    display: flex;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.26);
    column-gap:5px ;
    border-radius: 10px;
    width:auto;
    flex-wrap: wrap;
}

.SelectedMediaContainer{
    position: relative;
}
.removeBtn{
    position: absolute;
    top: 0;
    border:5px red solid;
}