.container{
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 10000;
}


/* .lightbox{
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 10000;
} */